<template>
    <div>
        <div class="table-responsive"
             data-toggle="lists"
             data-lists-sort-by="js-lists-values-date"
             data-lists-sort-desc="true"
             data-lists-values='["js-lists-values-name", "js-lists-values-company", "js-lists-values-phone", "js-lists-values-date"]'>

            <table class="table mb-0 thead-border-top-0 table-nowrap">
                <thead>
                <tr>
                    <th>
                        Nome
                    </th>

                    <th>
                        Data Inscrição
                    </th>

                    <th>
                        Progresso
                    </th>

                    <th>
                        Atividades Pendentes
                    </th>

                </tr>
                </thead>
                <tbody class="list"
                       id="contacts">

                <tr v-for="menteeMentoring in menteeMentorings" :key="menteeMentoring.id">

                    <td>

                        <router-link :to="`/mentorias/detalhes/${mentoring}/mentorando/${menteeMentoring.mentee.id}`">
                            <div class="media flex-nowrap align-items-center"
                                 style="white-space: nowrap;">
                                <div class="avatar avatar-sm mr-8pt">
                                    <img class="avatar-title rounded-circle"
                                         :src="menteeMentoring.mentee.photo_url" alt="">
                                </div>
                                <div class="media-body">

                                    <div class="d-flex flex-column">
                                        <p class="mb-0"><strong
                                            class="js-lists-values-name"> {{
                                                menteeMentoring.mentee.name
                                            }}</strong></p>
                                        <small
                                            class="js-lists-values-email text-50">{{
                                                menteeMentoring.mentee.email
                                            }}</small>
                                    </div>

                                </div>
                            </div>
                        </router-link>

                    </td>

                    <td class="text-center">
                        <small class="js-lists-values-date text-50">19 Fev 2019</small>
                    </td>

                    <td class="text-center">
                        <small class="js-lists-values-date text-50">{{ menteeMentoring.progress }}</small>
                    </td>

                    <td class="text-center">
                        <small class="js-lists-values-date text-50">{{ menteeMentoring.pendency }}</small>
                    </td>

                </tr>
                </tbody>
            </table>
        </div>

        <div class="card-footer p-8pt">

            <ul class="pagination justify-content-start pagination-xsm m-0">
                <li class="page-item" :class="{disabled: menteePage == 1}"
                    @click="getMentees(menteePage - 1)">
                    <a class="page-link"
                       href="#"
                       aria-label="Previous">
                                                    <span aria-hidden="true"
                                                          class="material-icons">chevron_left</span>
                        <span>Anterior</span>
                    </a>
                </li>
                <li v-for="n in menteePages" :key="n" class="page-item">
                    <a @click="getMentees(n)" class="page-link"
                       :class="{currentPage: n == menteePage}"
                       href="#"
                       aria-label="Page 1">
                        <span>{{ n }}</span>
                    </a>
                </li>
                <li class="page-item" :class="{disabled: menteePage == menteePages}"
                    @click="getMentees(menteePage + 1)">
                    <a class="page-link"
                       href="#"
                       aria-label="Next">
                        <span>Próximo</span>
                        <span aria-hidden="true"
                              class="material-icons">chevron_right</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>

import {endLoading, startLoading} from "@/components/composables/spinners";
import http from "@/http";

export default {
    data() {
        return {
            menteeMentorings: {},
            menteePages: '',
            menteePage: '',
            mentoring: this.$route.params.id
        }
    },
    methods: {
        getMentees(page) {
            if (page == this.menteePage || (this.menteePages && page > this.menteePages) || page == 0) {
                return;
            }
            startLoading('form', 'save');
            this.menteePage = page;
            this.$store.commit('api/setSpinner', true);
            http.get('menteeMentorings/?&page=' + this.menteePage + '&order_type=ASC&orderByAsc=&limit=4&with[]=mentee&mentoring_id=' + this.$route.params.id,
                    {
                        headers: {'Authorization': ` Bearer ${localStorage.getItem('token')} `}
                    })
                .then(response => {
                    this.menteeMentorings = response.data.data;
                    this.menteePages = response.data.pages;
                })
                .catch(error => {
                    console.error(error);
                })
                .finally(() => {
                    endLoading('form', 'save');
                    this.$store.commit('api/setSpinner', false);
                })
        },
    },
    mounted() {
        this.getMentees(1);
    }
}

</script>
