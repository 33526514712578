<template>
    <div>
        <div class="page-separator">
            <div class="page-separator__text">Feedbacks</div>
        </div>
        <div class="row mb-32pt">
            <div class="col-md-3 mb-32pt mb-md-0">
                <div class="display-1">{{ average }}</div>
                <div class="rating rating-24">
                                                <span class="rating__item"><span
                                                    class="material-icons">star</span></span>
                    <span class="rating__item"><span
                        class="material-icons">star</span></span>
                    <span class="rating__item"><span
                        class="material-icons">star</span></span>
                    <span class="rating__item"><span
                        class="material-icons">star</span></span>
                    <span class="rating__item"><span
                        class="material-icons">star_border</span></span>
                </div>
                <p class="text-muted mb-0">{{ feedbacks.total }} avaliações</p>
            </div>
            <div class="col-md-9">

                <div class="row align-items-center mb-8pt"
                     data-toggle="tooltip"
                     :data-title="percentages.five_stars + '% rated 5/5'"
                     data-placement="top">
                    <div class="col-md col-sm-6">
                        <div class="progress"
                             style="height: 8px;">
                            <div class="progress-bar bg-secondary"
                                 role="progressbar"
                                 :aria-valuenow="percentages.five_stars"
                                 :style="'width:' + percentages.five_stars + '%'"
                                 aria-valuemin="0"
                                 aria-valuemax="100"></div>
                        </div>
                    </div>
                    <div class="col-md-auto col-sm-6 d-none d-sm-flex align-items-center">
                        <div class="rating">
                                                        <span class="rating__item"><span
                                                            class="material-icons">star</span></span>
                            <span class="rating__item"><span
                                class="material-icons">star</span></span>
                            <span class="rating__item"><span
                                class="material-icons">star</span></span>
                            <span class="rating__item"><span
                                class="material-icons">star</span></span>
                            <span class="rating__item"><span
                                class="material-icons">star</span></span>
                        </div>
                    </div>
                </div>
                <div class="row align-items-center mb-8pt"
                     data-toggle="tooltip"
                     data-title="16% rated 4/5"
                     data-placement="top">
                    <div class="col-md col-sm-6">
                        <div class="progress"
                             style="height: 8px;">
                            <div class="progress-bar bg-secondary"
                                 role="progressbar"
                                 aria-valuenow="16"
                                 :style="'width:' + percentages.four_stars + '%'"
                                 aria-valuemin="0"
                                 aria-valuemax="100"></div>
                        </div>
                    </div>
                    <div class="col-md-auto col-sm-6 d-none d-sm-flex align-items-center">
                        <div class="rating">
                                                        <span class="rating__item"><span
                                                            class="material-icons">star</span></span>
                            <span class="rating__item"><span
                                class="material-icons">star</span></span>
                            <span class="rating__item"><span
                                class="material-icons">star</span></span>
                            <span class="rating__item"><span
                                class="material-icons">star</span></span>
                            <span class="rating__item"><span
                                class="material-icons">star_border</span></span>
                        </div>
                    </div>
                </div>
                <div class="row align-items-center mb-8pt"
                     data-toggle="tooltip"
                     :data-title="percentages.tree_stars + '% rated 3/5'"
                     data-placement="top">
                    <div class="col-md col-sm-6">
                        <div class="progress"
                             style="height: 8px;">
                            <div class="progress-bar bg-secondary"
                                 role="progressbar"
                                 :aria-valuenow="feedbacks.tree_stars"
                                 :style="'width:' + percentages.tree_stars + '%'"
                                 aria-valuemin="0"
                                 aria-valuemax="100"></div>
                        </div>
                    </div>
                    <div class="col-md-auto col-sm-6 d-none d-sm-flex align-items-center">
                        <div class="rating">
                                                        <span class="rating__item"><span
                                                            class="material-icons">star</span></span>
                            <span class="rating__item"><span
                                class="material-icons">star</span></span>
                            <span class="rating__item"><span
                                class="material-icons">star</span></span>
                            <span class="rating__item"><span
                                class="material-icons">star_border</span></span>
                            <span class="rating__item"><span
                                class="material-icons">star_border</span></span>
                        </div>
                    </div>
                </div>
                <div class="row align-items-center mb-8pt"
                     data-toggle="tooltip"
                     :data-title="percentages.two_stars + '% rated 2/5'"
                     data-placement="top">
                    <div class="col-md col-sm-6">
                        <div class="progress"
                             style="height: 8px;">
                            <div class="progress-bar bg-secondary"
                                 role="progressbar"
                                 :aria-valuenow="feedbacks.two_stars"
                                 :style="'width:' + percentages.two_stars + '%'"
                                 aria-valuemin="0"
                                 aria-valuemax="100"></div>
                        </div>
                    </div>
                    <div class="col-md-auto col-sm-6 d-none d-sm-flex align-items-center">
                        <div class="rating">
                                                        <span class="rating__item"><span
                                                            class="material-icons">star</span></span>
                            <span class="rating__item"><span
                                class="material-icons">star</span></span>
                            <span class="rating__item"><span
                                class="material-icons">star_border</span></span>
                            <span class="rating__item"><span
                                class="material-icons">star_border</span></span>
                            <span class="rating__item"><span
                                class="material-icons">star_border</span></span>
                        </div>
                    </div>
                </div>
                <div class="row align-items-center mb-8pt"
                     data-toggle="tooltip"
                     :data-title="percentages.one_star + '% rated 0/5'"
                     data-placement="top">
                    <div class="col-md col-sm-6">
                        <div class="progress"
                             style="height: 8px;">
                            <div class="progress-bar bg-secondary"
                                 role="progressbar"
                                 :aria-valuenow="feedbacks.one_star"
                                 :style="'width:' + percentages.one_star + '%'"
                                 aria-valuemin="0"
                                 aria-valuemax="100"></div>
                        </div>
                    </div>
                    <div class="col-md-auto col-sm-6 d-none d-sm-flex align-items-center">
                        <div class="rating">
                                                        <span class="rating__item"><span
                                                            class="material-icons">star</span></span>
                            <span class="rating__item"><span
                                class="material-icons">star_border</span></span>
                            <span class="rating__item"><span
                                class="material-icons">star_border</span></span>
                            <span class="rating__item"><span
                                class="material-icons">star_border</span></span>
                            <span class="rating__item"><span
                                class="material-icons">star_border</span></span>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="row mb-32pt">
            <div class="col">
                <div class="pb-16pt mb-16pt border-bottom row">
                    <div class="col-md-3 mb-16pt mb-md-0">
                        <div class="d-flex">
                            <a href="student-profile.html"
                               class="avatar avatar-sm mr-12pt">
                                <!-- <img src="LB" alt="avatar" class="avatar-img rounded-circle"> -->
                                <span class="avatar-title rounded-circle">LB</span>
                            </a>
                            <div class="flex">
                                <p class="small text-muted m-0">2 days ago</p>
                                <a href="student-profile.html"
                                   class="card-title">Laza Bogdan</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-9">
                        <div class="rating mb-8pt">
                                                <span class="rating__item"><span
                                                    class="material-icons">star</span></span>
                            <span class="rating__item"><span
                                class="material-icons">star</span></span>
                            <span class="rating__item"><span
                                class="material-icons">star</span></span>
                            <span class="rating__item"><span
                                class="material-icons">star</span></span>
                            <span class="rating__item"><span
                                class="material-icons">star_border</span></span>
                        </div>
                        <p class="text-70 mb-0">A wonderful course on how to start. Eddie
                            beautifully
                            conveys all essentials of a becoming a good Angular developer. Very glad
                            to
                            have taken this course. Thank you Eddie Bryan.</p>
                    </div>
                </div>

                <div class="pb-16pt mb-16pt border-bottom row">
                    <div class="col-md-3 mb-16pt mb-md-0">
                        <div class="d-flex">
                            <a href="student-profile.html"
                               class="avatar avatar-sm mr-12pt">
                                <!-- <img src="UK" alt="avatar" class="avatar-img rounded-circle"> -->
                                <span class="avatar-title rounded-circle">UK</span>
                            </a>
                            <div class="flex">
                                <p class="small text-muted m-0">2 days ago</p>
                                <a href="student-profile.html"
                                   class="card-title">Umberto Klass</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-9">
                        <div class="rating mb-8pt">
                                                <span class="rating__item"><span
                                                    class="material-icons">star</span></span>
                            <span class="rating__item"><span
                                class="material-icons">star</span></span>
                            <span class="rating__item"><span
                                class="material-icons">star</span></span>
                            <span class="rating__item"><span
                                class="material-icons">star</span></span>
                            <span class="rating__item"><span
                                class="material-icons">star_border</span></span>
                        </div>
                        <p class="text-70 mb-0">This course is absolutely amazing, Bryan goes* out
                            of
                            his way to really expl*ain things clearly I couldn&#39;t be happier, so
                            glad
                            I made this purchase!</p>
                    </div>
                </div>

                <div class="pb-16pt mb-24pt row">
                    <div class="col-md-3 mb-16pt mb-md-0">
                        <div class="d-flex">
                            <a href="student-profile.html"
                               class="avatar avatar-sm mr-12pt">
                                <!-- <img src="AD" alt="avatar" class="avatar-img rounded-circle"> -->
                                <span class="avatar-title rounded-circle">AD</span>
                            </a>
                            <div class="flex">
                                <p class="small text-muted m-0">2 days ago</p>
                                <a href="student-profile.html"
                                   class="card-title">Adrian Demian</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-9">
                        <div class="rating mb-8pt">
                                                <span class="rating__item"><span
                                                    class="material-icons">star</span></span>
                            <span class="rating__item"><span
                                class="material-icons">star</span></span>
                            <span class="rating__item"><span
                                class="material-icons">star</span></span>
                            <span class="rating__item"><span
                                class="material-icons">star</span></span>
                            <span class="rating__item"><span
                                class="material-icons">star_border</span></span>
                        </div>
                        <p class="text-70 mb-0">This course is absolutely amazing, Bryan goes* out
                            of
                            his way to really expl*ain things clearly I couldn&#39;t be happier, so
                            glad
                            I made this purchase!</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            feedbacks: {
                five_stars: 105,
                four_stars: 18,
                tree_stars: 7,
                two_stars: 4,
                one_star: 3,
                total: 137
            },
            percentages: {
                five_stars: 0,
                four_stars: 0,
                tree_stars: 0,
                two_stars: 0,
                one_star: 0,
            },
            average: 0,

        }
    },
    methods: {
        getAverage() {
            const feedbacks = this.feedbacks;

            this.percentages.five_stars = ((feedbacks.five_stars / feedbacks.total) * 100).toFixed(1);
            this.percentages.four_stars = ((feedbacks.four_stars / feedbacks.total) * 100).toFixed(1);
            this.percentages.tree_stars = ((feedbacks.tree_stars / feedbacks.total) * 100).toFixed(1);
            this.percentages.two_stars = ((feedbacks.two_stars / feedbacks.total) * 100).toFixed(1);
            this.percentages.one_star = ((feedbacks.one_star / feedbacks.total) * 100).toFixed(1);

            this.average = ((feedbacks.five_stars * 5 + feedbacks.four_stars * 4 + feedbacks.tree_stars * 3 + feedbacks.two_stars * 2 + feedbacks.one_star) / feedbacks.total).toFixed(1);
        }
    },
    mounted() {
        this.getAverage()
    }
}

</script>
