<template>
    <Layout>
        <div class="row" id="form">
            <div class="col-md-4 custom-scroll vh-100">
                <div class="card">

                    <div class="card-header">
                        <div class="position-relative">
                            <img :src="data.photo" alt="logo_mentoria" class="w-100">
                            <div class="avatar-xs p-0 rounded-circle profile-photo-edit pointer"
                                 style="position: absolute;bottom: -6px;right: -10px;">
                                <router-link :to="'/mentorias/cadastrar/' + data.id">
                                        <span class="avatar-title rounded-circle bg-primary text-white">
                                          <i class="fa fa-pencil-alt"></i>
                                        </span>
                                </router-link>
                            </div>
                        </div>


                        <div class="card-title text-center mt-3">{{ data.title }}</div>

                        <div class="text-justify mt-2 d-flex">
                            <span><b>Descrição: </b>
                                <span v-html="data.description" class="text-muted"></span>
                            </span>
                        </div>

                        <div class="text-justify mt-2 d-flex">
                            <span><b>Resumo: </b>
                                <span v-html="data.resume" class="text-muted"></span>
                            </span>
                        </div>

                        <div class="text-justify mt-2 d-flex">
                            <span><b>Valor: </b>
                                <span class="text-muted">{{ maskMoneyTable(data.price) }}</span>
                            </span>
                        </div>

                        <div class="text-justify mt-2 d-flex">
                            <span><b>Tipo: </b>
                                <span class="text-muted">{{ data.type_name }}</span>
                            </span>
                        </div>

                        <div class="text-justify mt-2 d-flex">
                            <span style="word-break: break-all"><b>Hotsite Mentoria: </b>
                                <span class="text-muted">
                                    <a :href="data.link" class="href" target="_blank">
                                    {{ data.link }}
                                    </a>
                                </span>
                            </span>
                        </div>
                    </div>

                    <div class="card-body">

                    </div>

                </div>
            </div>
            <div class="col-md-8 custom-scroll vh-100">


                <div class="card">
                    <div class="card-header">
                        <div class="card-title">
                            Indicadores
                        </div>
                    </div>
                    <div class="card-body custom-scroll">
                        <apexchart class="apex-charts" height="350" dir="ltr" :series="series"
                                   :options="chartOptions"></apexchart>
                    </div>
                    <!-- <div class="card-body custom-scroll">
                        <apexchart class="apex-charts" height="350" dir="ltr" :series="secondSeries"
                                   :options="secondChartOptions"></apexchart>
                    </div> -->
                </div>


                <div class="card dashboard-area-tabs p-relative o-hidden mb-32pt">
                    <div class="card-header p-0 nav">
                        <div class="row no-gutters" role="tablist">
                            <div class="col-auto">
                                <div
                                    @click="list_active = 'mentees'"
                                    class="dashboard-area-tabs__tab card-body d-flex flex-row align-items-center justify-content-start pointer"
                                    :class="list_active === 'mentees' ? 'active' : ''"
                                >
                                    <span class="flex d-flex flex-column">
                                        <strong>Mentorados</strong>
                                    </span>
                                </div>
                            </div>
                            <!-- <div class="col-auto">
                                <div
                                    @click="list_active = 'forms'"
                                    class="dashboard-area-tabs__tab card-body d-flex flex-row align-items-center justify-content-start pointer"
                                    :class="list_active === 'forms' ? 'active' : ''"
                                >
                                    <span class="flex d-flex flex-column">
                                        <strong>Formulários</strong>
                                    </span>
                                </div>
                            </div> -->
                            <!-- <div class="col-auto">
                                <div
                                    @click="list_active = 'feedbacks'"
                                    class="dashboard-area-tabs__tab card-body d-flex flex-row align-items-center justify-content-start pointer"
                                    :class="list_active === 'feedbacks' ? 'active' : ''"
                                >
                                    <span class="flex d-flex flex-column">
                                        <strong>Feedbacks</strong>
                                    </span>
                                </div>
                            </div> -->
                        </div>
                    </div>
                    <div class="card-body custom-scroll">

                        <div v-if="list_active === 'mentees'">
                            <TableMentee/>
                        </div>

                        <div v-if="list_active === 'forms'">

                            <div class="page-separator">
                                <div class="page-separator__text">Formulários</div>
                            </div>

                            <div class="card mb-0">
                                <div class="table-responsive"
                                     data-toggle="lists"
                                     data-lists-sort-by="js-lists-values-orders-name"
                                     data-lists-values='["js-lists-values-orders-name", "js-lists-values-orders-date", "js-lists-values-orders-amount"]'>
                                    <table class="table mb-0 table-nowrap thead-border-top-0">
                                        <thead>
                                        <tr>
                                            <th class="text-center">Título</th>
                                            <th class="text-center">Quantidade de Questões</th>
                                            <th class="text-center">Retornos</th>
                                            <th class="text-center">Ações</th>
                                        </tr>
                                        </thead>
                                        <tbody class="list">
                                        <tr>
                                            <td class="text-center">Básico</td>
                                            <td class="text-center">
                                                10
                                            </td>
                                            <td class="text-center">
                                                12/20
                                            </td>
                                            <td class="text-center">
                                                <router-link to="/formularios/cadastrar/1">
                                                    <i class="fas fa-pencil-alt text-info pointer mx-1"
                                                       title="Editar"></i>
                                                </router-link>
                                                <i class="fas fa-trash text-danger pointer mx-1" title="Excluir"></i>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text-center">Inermedário</td>
                                            <td class="text-center">
                                                16
                                            </td>
                                            <td class="text-center">
                                                8/20
                                            </td>
                                            <td class="text-center">
                                                <router-link to="/formularios/cadastrar/2">
                                                    <i class="fas fa-pencil-alt text-info pointer mx-1"
                                                       title="Editar"></i>
                                                </router-link>
                                                <i class="fas fa-trash text-danger pointer mx-1" title="Excluir"></i>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text-center">Avançado</td>
                                            <td class="text-center">
                                                25
                                            </td>
                                            <td class="text-center">
                                                3/20
                                            </td>
                                            <td class="text-center">
                                                <router-link to="/formularios/cadastrar/3">
                                                    <i class="fas fa-pencil-alt text-info pointer mx-1"
                                                       title="Editar"></i>
                                                </router-link>
                                                <i class="fas fa-trash text-danger pointer mx-1" title="Excluir"></i>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>


                        <Feedbacks v-if="list_active === 'feedbacks'"/>

                    </div>

                </div>
            </div>
        </div>
    </Layout>
</template>

<script>

import Layout from '@/components/layout/main.vue';
import Feedbacks from "@/components/base/feedbacks.vue";
import TableMentee from "@/views/mentoring/details/table-mentee.vue";
import http from "@/http";
import {maskMoneyTable} from "../../../components/composables/masks";
import {endLoading, startLoading} from "@/components/composables/spinners";


export default {
    components: {
        Layout,
        Feedbacks,
        TableMentee
    },
    data() {
        return {
            list_active: 'mentees',
            series: [{
                name: 'Venda Mensal',
                type: 'column',
                data: []
            }, {
                name: 'Mentorados',
                type: 'line',
                data: []
            }],
            chartOptions: {
                chart: {
                    height: 350,
                    type: 'line',
                },
                stroke: {
                    width: [0, 4]
                },
                dataLabels: {
                    enabled: true,
                    enabledOnSeries: [1]
                },
                labels: [],
                title: {
                    text: 'Venda Mensal',
                    align: 'center',
                },
                yaxis: [{
                    title: {
                        text: 'Venda Mensal',
                    },

                }, {
                    opposite: true,
                    title: {
                        text: 'Mentorados'
                    }
                }]
            },
            secondSeries: [{
                name: 'Quantidade de Mentorados',
                type: 'column',
                // data: [3, 4, 3, 2]
                data: [2, 3, 4, 3]
            }],
            secondChartOptions: {
                chart: {
                    height: 350,
                    type: 'line',
                },
                stroke: {
                    width: [0, 4]
                },
                legend: {
                    show: true,
                    showForSingleSeries: true,
                },
                dataLabels: {
                    enabled: true,
                    enabledOnSeries: [1]
                },
                // labels: ['75-100%','51-75%', '26-50%' ,'0-25%'],
                labels: ['0-25%', '26-50%', '51-75%', '75-100%'],
                title: {
                    text: 'Progresso de Mentorados',
                    align: 'center',
                },
                yaxis: [{
                    title: {
                        text: 'Quantidade de Mentorados',
                    },
                },],
            },
            data: {
                id: 0,
                title: '',
                resume: '',
                description: '',
                policy: '',
                logo: '',
                video: '',
                category_id: '',
                type: '',
                link: '',
                price: '',
                photo: '',
            },
        }
    },
    methods: {
        maskMoneyTable,
        getData(id) {
            startLoading('form', 'save');
            this.$store.commit('api/setSpinner', true);
            http.get('mentorings/'+ id, {
                    headers: {'Authorization': ` Bearer ${localStorage.getItem('token')} `}
                })
                .then(response => {
                    this.data = response.data;

                    var style = document.createElement('style');
                    style.type = 'text/css';

                    var css = `
            p .ql-size-large {
                font-size: 1.5em !important;
            }
            p .ql-size-small {
                font-size: .75em;
            }
            p .ql-size-huge {
                font-size: 2.5em;
            }
        `;

                    if (style.styleSheet) {
                        style.styleSheet.cssText = css;
                    } else {
                        style.appendChild(document.createTextNode(css));
                    }

                    document.head.appendChild(style);
                })
                .catch(error => {
                    console.error(error);
                })
                .finally(() => {
                    endLoading('form', 'save');
                    this.$store.commit('api/setSpinner', false);
                })
        },
        getChartData(id) {
            let self = this
            http.get('mentors/dashboard-chart?id=' + id)
                .then(response => {

                    self.series = [
                        {
                            name: 'Vendas por mês',
                            type: 'column',
                            data: response.data['value_by_month']
                        }, {
                            name: 'Mentorados',
                            type: 'line',
                            data: response.data['count_by_month']
                        }
                    ]

                    self.chartOptions = {
                        noData: {
                            text: 'Loading...'
                        },
                        chart: {
                            height: 350,
                            type: 'line',
                        },
                        stroke: {
                            width: [0, 4]
                        },
                        dataLabels: {
                            enabled: true,
                            enabledOnSeries: [1]
                        },
                        labels: response.data['labels'],
                        yaxis: [{
                            title: {
                                text: 'Vendas por mês',
                            },

                        }, {
                            opposite: true,
                            title: {
                                text: 'Mentorados'
                            }
                        }]
                    }
                })
                .catch((e) => {
                    console.error(e);
                    // this.$notifyError('Erro ao excluir Módulo!');
                })
        }
    },
    mounted() {
        const id = this.$route.params.id;
        if (id) {
            this.getData(id)
            this.getChartData(id)
        }
    },
}

</script>
<style>
.currentPage {
    z-index: 2;
    color: rgba(6, 7, 8, 0.7);
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6;
}
</style>
